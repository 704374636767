import React, { Component } from 'react';
import moment from 'moment';
import t from './i18n';
import { pweb , pwebs, Loader , Utils, Route, Link,
    FormInputText, FormButtons, FormInputContainer , BoxContainer, SweetAlert, 
    FormInputSelect, FormInputSelectOption, Row, FormDashline,
    FormInputRadio } from './Common.js';
import { OrderShippingAddress } from './Order.js';
import { ProfileData, ShippingAddress } from './User.js';
import {LocalCache, logComponentDiff } from './Globals';
import './PrivateArea.css';

const OrderCreationUtils  = {
    packCode: function( object ){
        if( !object ) return '?-?';
        return `${object.num_eser_cont-2000}-${object.prg_doc}`;
    }
};
class ProductQuadBox extends Component {

    render(){
        return <div className="contact-box center-version ProductQuadBox">
            
            <div className="">
                <div className="text-center">
                    <div className="m-t-xs font-bold"></div>
                </div>
            </div>
            <div className="text-center">
                <h3><strong>{this.props.product.cda_art_alias}</strong></h3>
                <p><i className="fa"></i> {this.props.product.category}</p>
            </div>
            <div className="clearfix"></div>
        </div>;
    }
}

class ProductCategoryViewer extends Component {
    render(){

        if(!this.props.products)
            return <Loader />;

        return <Row style={{padding: '20px'}}>

            <h3>{this.props.category}</h3>

            {this.props.products.filter( product => { 
                return product.category === this.props.category;
            }).map( product => {
                return <div className="col-md-3"  onClick={se=>{ if( this.props.onClick ) this.props.onClick( product ) } } >
                    <ProductQuadBox product={product} />
                </div>;
            })}
        </Row>;
        
    }
}
class ProductChoiceForm extends Component {

    constructor( props ){
        super( props );
        
        this.state = {
            products: pwebs().getState().products,
        };

        pwebs().subscribe( () => { this.setState( pwebs().getState() )} );
    }

    render(){

        if( !this.state.products )
            return <Loader />;

        let categories = [];

        this.state.products.map( product => {
            if( categories.indexOf( product.category ) === -1)
                categories.push( product.category );
            return product;
        });

        return <Row> 

            {categories.map( category => {
                return <ProductCategoryViewer products={this.state.products} category={category} 
                    onClick={se=>{ if( this.props.onProductSelected ) this.props.onProductSelected( se ) }}/>
            })}
            

            { /* this.state.products.map( product => {
            return <div onClick={se=>{ if( this.props.onProductSelected ) this.props.onProductSelected( product ) }}
                className="col-md-4 col-lg-3 ProductChoiceForm">
                <ProductQuadBox product={product} />
            </div> 
            }) */ }
        </Row>;
        

    }

}

class OrderCreationIndex extends Component {
    render(){
        return null;
    }
}

function OrderCreationForm_VarRow( props ){
    const row = props.row;

    return (
        <tr id={`OrderViewRow${row.prg_riga}`} style={{}}>
            <td style={{textAlign: 'left'}} >
                <h4>VAR <span className="small text-muted">/{row.prg_art}</span></h4>
                <div className="small text-muted"></div>
            </td>
            <td className="text-center"><h4>{row.qta_pezzi}</h4></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="text-right">{Utils.formatCurrency(row.val_movim_netto)}</td>
            {/*<td className="text-right">{Utils.formatPercent(0)}</td>*/}
            <td className="text-right">{Utils.formatCurrency(row.val_movim_lordo)}</td>
            <td></td>
            <td>
                
            </td>
        </tr>
    );
}

function OrderCreationForm_DefaultRow( props ){
    const row = props.row;
    const product = pweb().getProduct( row.cda_art );

    let rowColorOptions = false;

    let rowColor = '-';

    rowColorOptions = row.options ? row.options['COL'] : false;

    if( rowColorOptions !== false && product.options ){

        product.options.map( option => {
            if( option.name !== 'COL' )
                return option;

            option.values.map( optionValue => {
                if( optionValue.name !== rowColorOptions )
                    return;
                
                rowColor = optionValue.alias;
            })
        });

    }


    let options_text = [];

    try {
        Object.keys( row.options ).map( option_name => {
            let product_option = product.options.filter( o => { return o.name == option_name });

            if( !product_option.length )
                return;
            else
                product_option = product_option[0];

            product_option.values.map( value => {
                if( value.name != row.options[option_name] )
                    return;

                options_text.push( `${product_option.alias}: ${value.alias}` );
            });

        });
    } catch (e) {

    }

    return (
        [ <tr id={`OrderViewRow${row.prg_riga}`} style={{}}>
            <td style={{textAlign: 'left'}} >
                <h4>{product ? product.cda_art_alias : row.cda_art} </h4>
                <div className="small text-muted" style={{position:"absolute"}}>
                    {row.des_note ? 'Nota: ' + row.des_note : '' }
                    {options_text.join(', ')}
                </div>
            </td>
            <td className="text-center"><h4>

                <a className="btn btn-default btn-xs"
                    onClick={se=>{props.onQtyChange(row,-1)}}><i className="fa fa-minus"></i></a>

                <span style={{margin: '10px'}}>{row.qta_pezzi}</span>

                <a className="btn btn-default btn-xs"
                    onClick={se=>{props.onQtyChange(row,1)}}><i className="fa fa-plus"></i></a>

            </h4></td>
            <td>{rowColor}</td>
            <td>{row.val_base_finita}</td>
            <td>{row.val_altezza_finita}</td>
            <td>{row.qta_unimis_doc.toFixed(2)}</td>
            <td className="text-right">
                {props.row.flg_preventivo ? '-' : Utils.formatCurrency(row.val_movim_netto)}</td>
            {/*<td className="text-right">{Utils.formatPercent(0)}</td>*/}
            <td className="text-right">
                {props.row.flg_preventivo ? '-' : Utils.formatCurrency(row.val_movim_lordo)}</td>
            <td>{row.des_subcliente}</td>
            <td>
                { props.editable ? [
                <Link href={`/orderc/rows/edit/${Utils.getUrlParam(-1)}/${row.prg_riga}`} className="btn btn-default"
                    data-toggle="tooltip" data-placement="left" title="Modifica">
                    <i className="fa fa-edit fa-fw"></i></Link> ,

                <a className="btn btn-default" style={{marginLeft: '10px'}} onClick={se=>{props.onRowDelete(row)}}
                    data-toggle="tooltip" data-placement="left" title="Elimina riga">
                    <i className="fa fa-trash fa-fw"></i></a>
                ] : <span></span> }
            </td>
        </tr> ]
    );

}

class OrderCreationForm extends Component {
    
    constructor(props){
        super(props);

        this.state = {
            loading: true,
            updating: false,
            errorMessage: false,
            printModal: false
        };

        this.onRowDelete = this.onRowDelete.bind(this);
        this.onQtyChange = this.onQtyChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onSaveAndSend = this.onSaveAndSend.bind(this);
        this.onVoid = this.onVoid.bind( this );
        this.onRestore = this.onRestore.bind( this );
        this.saveQuotation = this.saveQuotation.bind( this );
    }

    componentDidMount(){

        pwebs().dispatch( pweb().setHeaderBreadcrumbs([
            {label: t(`Articoli e Totali`) , url:'#'},
        ]));

        if( Utils.getUrlParam( -1 ) === 'new' ){
            pweb().fetch( 'quotations' , 'new' , msg => {
                /*if( msg.status )
                    Route.pushLocation( '/orderc/edit/' + OrderCreationUtils.packCode( msg.data ) );*/

                /*pweb().fetch( 'quotations' , 'get' , OrderCreationUtils.packCode( msg.data ), msg => {
                    this.applyData( { loading: false, ...msg.data } );
                });*/

                Route.pushLocation( `/orderc/rows/new/${OrderCreationUtils.packCode(msg.data)}` )
            });
        }

        if( Utils.getUrlParam( -2 ) === 'edit' ){
            pweb().fetch( 'quotations' , 'get' , Utils.getUrlParam(-1), msg => {
                this.applyData( { loading: false, ...msg.data } );
            });
        }
    }

    applyData( data ){
        if( !data ) return;

        this.setState( data );
        this.setState({errorMessage:false});
    }

    onRowDelete( row ){

        if( this.state.updating )
            return;

        const msg = `Eliminare la riga indicata?`
        SweetAlert.show( '' , msg , [
            { caption: t('Yes') , onClick: se=>{ 

                this.setState({updating: true, errorMessage: false});

                pweb().fetch( 'quotations' , 'rows' , 'delete' , OrderCreationUtils.packCode( row ), row.prg_riga , msg => {
                    pweb().fetch( 'quotations' , 'get' , Utils.getUrlParam(-1), msg => {
                        this.applyData( { loading: false, ...msg.data , updating: false } );
                        SweetAlert.close()
                    });
                });
            } },
            { caption: t('Cancel') , onClick: se=>{SweetAlert.close()} },
        ]);

        
    }

    hasFlgPreventivo(){
        
    }

    onQtyChange( row , diff ){

        if( this.state.updating )
            return;

        row.qta_pezzi += diff;
        if( row.qta_pezzi < 1 )
            row.qta_pezzi = 1;
            
        row = {
            ...row,
            quotationCode:  Utils.getUrlParam(-1)
        };

        this.setState({updating: true, errorMessage: false});
        
        pweb().fetch( 'quotations' , 'rows' , 'save' , row , msg => {
            pweb().fetch( 'quotations' , 'rows' , 'confirm' , OrderCreationUtils.packCode( this.state ) , msg.data.prg_riga , msg => {
                pweb().fetch( 'quotations' , 'get' , Utils.getUrlParam(-1), msg => {
                    this.setState( { loading: false, ...msg.data , updating: false } );
                });
            });
        });
    }

    onSave( se ){
        se.preventDefault();
        if( this.state.updating ) return;

        this.setState({updating: true});

        pweb().fetch( 'quotations' , 'saveDraft' , OrderCreationUtils.packCode( this.state ) , msg => {
            this.setState({updating: false});
            
            if( msg.status )
                Route.pushLocation('/dashboard');
        } );

        
    }

    saveQuotation(){
        if( this.state.updating )
            return;

        this.setState({updating: true, errorMessage: false});

        pweb().fetch( 'quotations' , 'save' , this.state , msg => {
            this.setState( { loading: false, ...msg.data , updating: false } );
        });
    }

    onRestore( se ){
        pweb().fetch( 'quotations' , 'void', OrderCreationUtils.packCode( this.state ) , (msg) => {
            //if( cb ) cb( !msg.status );
            LocalCache.groupClear( 'fetch' );
            window.location.reload(); // Pls do not hurt me
        });
    }

    onVoid( se ){
        const msg = t(`Sei sicuro di voler annullare il preventivo?` );

        SweetAlert.show( t('Conferma') , msg , [
            { caption: t('Yes') , onClick: se=>{ 

                pweb().fetch( 'quotations' , 'void', OrderCreationUtils.packCode( this.state ) , (msg) => {
                    //if( cb ) cb( !msg.status );
                    LocalCache.groupClear( 'fetch' );
                    window.location.reload(); // Pls do not hurt me
                });

                SweetAlert.close() } },
            { caption: t('Cancel') , onClick: se=>{SweetAlert.close()} },
        ]);
    }

    onSaveAndSend( se ){
        se.preventDefault();
        if( this.state.updating ) return;
        
        this.setState({updating: true});
        pweb().fetch( 'quotations' , 'sync' , OrderCreationUtils.packCode( this.state ) , msg => {

            this.setState({updating: false});

            if( msg.status )
                Route.pushLocation('/order/quotations');
            else {
                this.setState({errorMessage: 'Errore durante il salvataggio dell\'ordine. Controllare evenutale righe non valide'});
            }

        });
    }

    render(){ 
        if( this.state.loading )
            return <Loader />
            
        return <div> 
            <div className="row" style={{marginBottom: '10px'}}>
                <div className="col-md-12 OrderViewActions">
                
                </div>
            </div>

            <PrintModal
                num_eser_cont={this.state.num_eser_cont}
                prg_doc={this.state.prg_doc}
                visible={this.state.printModal}
                cda_serie_num={"WU"}
                cda_polo_num={"PRINED"}
                cda_tipo_doc_num={"OV"}

                onClose={se=>{this.setState({
                    printModal: false,
                } )}} />

            <div className="row">
                <div className="col-md-6">

                    <BoxContainer title={t('Order informations')} className="OrderViewInfo" >

                        <dl className="dl-horizontal">

                            <dt>{t('Order Num: ')}</dt>
                            <dd>{OrderCreationUtils.packCode( this.state )}</dd>

                            <dt>{t('Creation date: ')}</dt>
                            <dd>{ this.state.dat_creazione ? moment(this.state.dat_creazione).format('D MMMM YYYY') : '-'}</dd>

                            <dt>{t('Data partenza prevista: ')}</dt>
                            <dd>{ this.state.dat_partenza ? moment(this.state.dat_partenza).format('D MMMM YYYY') : '-'}</dd>

                            <dt>{t('Modalità di pagamento: ')}</dt>
                            <dd>{this.state.des_pag}</dd>

                            {/* <dt>{t('Yours reference: ')}</dt>
                            <dd>{this.state.order.des_riferimento}</dd> */}
                            
                        </dl>
                    </BoxContainer>

                </div>
                <div className="col-md-6">
                    <BoxContainer title={t('Shipping')} >
                        { this.state.cdn_dm ? <OrderShippingAddress cdn_dm={this.state.cdn_dm} order={this.state} /> : <ProfileData /> }

                        <div className="text-right">
                            {this.state.flg_attesa_sync == "0" && <Link href={"/orderc/dm/" + OrderCreationUtils.packCode( this.state )} 
                                className="btn btn-default text-right">Cambia destinazione</Link> }
                        </div>

                    </BoxContainer>
                </div>
            </div>

            <div className="row" style={{marginBottom: '10px'}}>
                <div className="col-md-12 OrderViewActions">
                

                </div>
            </div>

            <div className="row">

                <div className="col-sm-12">
                    <a  target="_blank" className="btn btn-default" onClick={se=>{se.preventDefault(); this.setState({printModal: true})}} >
                        <i className="fa fa-print"></i> Stampa preventivo</a>

                    {(this.state.flg_annull < 1 && this.state.flg_canc_logica < 1 && this.state.flg_attesa_sync < 1 ) && <button className="btn btn-primary" onClick={this.onVoid}
                        style={{marginLeft: '10px'}} >
                        <i className="fa fa-times"></i> ANNULLA preventivo</button> }

                    {this.state.flg_annull > 0 && <button className="btn btn-default" onClick={this.onRestore}
                        style={{marginLeft: '10px'}} >
                        <i className="fa fa-times"></i> Ripristina preventivo</button> }

                </div>

            </div>

            {this.state.flg_attesa_sync > 0 && <div className="row">
                <div className="col-sm-12">
                    <p className="text-info" style={{backgroundColor: '#346de9',
                        color: 'white',
                        marginTop: '10px',
                        fontSize: '15pt',
                        padding: '20px',
                        borderRadius: '10px'}}>
                        Ordine inviato
                    </p>
                </div>
            </div>}

            <div className="row">
                <div className="col-sm-12">
                    <BoxContainer style={{marginTop: '20px'}}>
                       
                        <div className="table-responsive">
                            <table className="table table-striped">
                            
                                <thead>
                                    <tr>
                                        <th style={{textAlign: 'left'}}>{t('Article')}</th>
                                        <th className="text-center">{t('Qty')}</th>
                                        <th>{t('Colore')}</th>
                                        <th>{t('Width')}</th>
                                        <th>{t('Height')}</th>
                                        <th>{t('MQ')}</th>
                                        <th className="text-right">{t('Subtotal')}</th>
                                        {/* <th className="text-right">{t('Discount')}</th> */}
                                        <th className="text-right">{t('Price')}</th>
                                        <th>{t('Subclient')}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.rows && this.state.rows.filter( row => { return row.cda_art != 'VAR' } ).map( row => {
                                        return <OrderCreationForm_DefaultRow row={row} editable={this.state.flg_attesa_sync=="0"}
                                            onRowDelete={this.onRowDelete} onQtyChange={this.onQtyChange}
                                        />
                                    } )}

                                    {this.state.rows && this.state.rows.filter( row => { return row.cda_art == 'VAR' } ).map( row => {
                                        if( this.state.flg_preventivo ) 
                                            return null;
                                        return <OrderCreationForm_VarRow row={row} />;
                                    } )}
                                </tbody>

                            </table>

                            <div className="text-center">
                                {this.state.flg_attesa_sync == "0" && <Link className="btn btn-default" data-toggle="tooltip" data-placement="left" title=""
                                    style={{margin:'5px'}}
                                    href={`/orderc/rows/new/${OrderCreationUtils.packCode(this.state)}`}>

                                    <i className="fa fa-plus"></i> {t('Aggiungi articolo')}
                                </Link> }
                            </div>

                            

                        </div>

                        </BoxContainer>

                        {this.state.flg_preventivo > 0 && <Row>
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="alert alert-warning">
                                    <i className="fa fa-exclamation-triangle fa-2x" style={{
                                        float: 'left',
                                        marginRight: '15px'
                                    }}></i>
                                    <h4>Questo ordine verrà inserito come preventivo in attesa di verifica da parte dei nostri operatori</h4>
                                </div>
                            </div>
                        </Row>}

                        <Row>
                            <div className="col-lg-4">
                                <OrderRowCreationFormTotals label="Prezzo di listino" unimis={false}  
                                    net={this.state.val_movim_netto_listino} total={this.state.val_movim_lordo_listino}
                                    vat={this.state.val_movim_iva_listino} flg_preventivo={this.state.flg_preventivo} />
                            </div>

                            <div className="col-lg-4">
                                <div className="text-center" >
                                    { this.state.flg_attesa_sync != "0" ? 

                                    <a href="#" className="btn btn-default" onClick={
                                        se=>{se.preventDefault();Route.pushLocation('/order/quotations');}} style={{width: '140px'}}>
                                    <i className="fa "></i> Indietro</a> : [
                                 
                                    <button href="#" className="btn btn-default mr-5" style={{marginLeft:'5px',marginBottom:'5px',width:'210px'}} onClick={this.onSave}  >
                                        {this.state.updating ? <i className="fa fa-spin fa-spinner"></i> : 'Salva preventivo' }</button> , 
                                    <button href="#" className="btn btn-primary" style={{marginLeft:'5px',marginBottom:'5px', width:'210px'}} onClick={this.onSaveAndSend}  >
                                     
                                    {this.state.updating ? <i className="fa fa-spin fa-spinner"></i> : 
                                        (this.state.flg_preventivo ? 'Richiedi preventivo' : 'Salva e invia ordine' )
                                    }</button> ] }

                                </div>
                            </div>

                            <div className="col-lg-4">
                                <OrderRowCreationFormTotals label="Prezzo a lei riservato" unimis={false}  
                                    vat={this.state.val_movim_iva} flg_preventivo={this.state.flg_preventivo}
                                    net={this.state.val_movim_netto} total={this.state.val_movim_lordo} />
                            </div>
                        </Row>
                    
                </div>
            </div>

            <Row>
                <div className="col-md-12 text-center" style={{color: 'red',fontSize: '18pt'}}>
                    {this.state.errorMessage && <p>{this.state.errorMessage}</p>}
                </div>
            </Row>

            <Row>
                <div className="col-md-6"></div>
            </Row>
        </div>; 
    }

}

class OptionGenericSelectForm extends Component {
    render(){

        const itemComponent = (item, onClick, isSelected, index) => {
            const className = isSelected ? 'OptionGenericSelectFormItem OrderC_AutoCompleteItems OrderC_AutoCompleteItemsSelected' 
                : 'OrderC_AutoCompleteItems';
    
            return <div className={className + " col-xs-12"} 
                onClick={onClick} key={item.value} >

                {index === 0 && <Row className="OptionGenericSelectForm_AutoCompleteHeader row">
                    <div className={'col-xs-2'}>Cod.</div>
                    <div className={'col-xs-10'}>Descrizione</div>
                </Row>}

                <Row>
                    <div className={'col-xs-2'}>{item.value}</div>
                    <div className={'col-xs-10'}>{item.label}</div>
                </Row>
            </div>;
        };

        // Calcolo una larghezza per la input di autocmpletge
        // in base alle label: regolato a mano 12 sembra andar bene
        let width = 150;
        this.props.option.values.map( opt => {
            if( !opt.alias ) return;

            if( opt.alias.length * 12 > width )
                width = opt.alias.length * 12;
        });

        const className = ( this.props.className ? this.props.className : '' ) + 
            ( this.props.value == '' || this.props.value == 'NUL' ? ' OptionGenericSelectForm_AutoCompleteError' : '' );

        return <AutoCompleteInput {...this.props} className={className} items={query => {
            return this.props.option.values.filter( opt => {
                if( opt.name === 'NUL' ) return false;
                if( !opt.flg_variante_abil ) return false;
                if( query.trim().length < 1 ) return true;
                if( opt.alias.match( new RegExp( query , 'i' ) ) )
                    return true;
                if( opt.name && opt.name.match( new RegExp( query , 'i' ) ) )
                    return true;
                return false;
            }).map( opt => {
                return { value: opt.name , label: opt.alias };
            });
        }} onChange={se=>{this.props.onChange(se.value)}}
            inputProps={{style:{width: width+'px'}}}
            itemComponent={itemComponent}
         />;
    }
}

function ImageCorneredQuadBox(props){
    return null;
}

function OptionColorFormSingleColor( props ){

    const type = props.gallery ? props.gallery : 'small';

    const colorPositions = {
        "901": { x: 25,    y: 122 },
        "101": { x: 245,   y: 122 }, 
        "801": { x: 465,   y: 122 }, 
        "APR": { x: 684,    y: 122 },
        "BVE": { x: 903,    y: 122 }, 
        "9FG": { x: 25,    y: 379 },
        "F1G": { x: 245,   y: 379 },
        "MMU": { x: 465,   y: 379 }, 
        "71G": { x: 684,    y: 379 },
        "F35": { x: 903,    y: 379 },
        "NOP": { x: 25,    y: 634 }, 
        "60P": { x: 245,   y: 634 },

        "PCN": { x: 1264,   y: 122 },
        "D3R": { x: 1820,   y: 122 },
        "C21": { x: 1264,   y: 360 },
        "N10": { x: 1820,   y: 360 },
        "PNS": { x: 1264,   y: 594 },
        "M37": { x: 1820,   y: 594 },
        "DR1": { x: 1264,   y: 830 },
        "DRS": { x: 1820,   y: 830 },
        "C24": { x: 1264,   y: 1065 },
        "DBE": { x: 1820,   y: 1065 },

        "GRA": { x: 2,   y: 1011 },
        "VRA": { x: 222,   y: 1011 },
        "MRA": { x: 442,   y: 1011 },   

        "0BX": { x: 2,   y: 1385 },
        "OAX": { x: 222,   y: 1385 },
        "GFS": { x: 442,   y: 1385 },   
        "TMD": { x: 661,   y: 1385 },
        "RBY": { x: 880,   y: 1385 },
    };

    if( type === 'small' )
        return <div className="col-md-12">
            <p onClick={se=>{console.log(props.name);props.onClick( props.name )}}>
            <b>Codice {props.name}</b> / {props.alias}
        </p></div>;

    let style={};

    Object.keys( colorPositions ).map( color => {
        if( props.name == color )
            style.backgroundPosition = `${colorPositions[color].x*-1}px ${colorPositions[color].y*-1}px`;
    })

    if( type === 'extrabig' )
        style.maxWidth = '350px';
    return <div className={ (type==='extrabig'?'col-md-6':'col-md-4 ') + " OptionColorFormSingleColor "} 
        onClick={se=>{console.log(props.name);props.onClick( props.name )}} >
            <div className="OptionColorFormSingleColor_BigBox" style={style} />
            <Row style={{minHeight: '3em'}}>
                <div className="col-md-3 text-left" style={{fontSize: '8pt'}}><b>{props.name}</b></div>
                <div className="col-md-9 text-right" style={{fontSize: '8pt'}}>{props.alias}</div>
            </Row>
    </div>;
}

class OptionColorForm extends Component {

    constructor(props){
        super(props);

        this.state = {
            filter: false,
            focus: false,
            colorsCatalog: []
        };

        /*pwebs().subscribe( () => { 
            if( pwebs().getState().productsColorCatalog ) 
                this.setState( { colorsCatalog: pwebs().getState().productsColorCatalog } );
        } );*/

        this.textInput = React.createRef();

        this.onChange = this.onChange.bind( this );
    }

    onChange( value ){

        if( this.props.onChange )
            this.props.onChange( value );

        this.setState({focus: false,filter: false});
    }

    applyFilteredProduct(){
        const colors = this.getFilteredColors();

        let found = undefined;

        colors.map( color => {
            color.values.map( value => {
                if( found === undefined ){
                    found = value.name;
                    return value;
                }

                if( found !== undefined && value.name !== found )
                    found = false;
            });
        } );

        if( found !== false && found !== undefined ){
            this.onChange( found );
            this.setState({focus: true});
        }
    }

    getFilteredColors(){
        let colors = this.getColors();

        if( this.state.filter !== false ){
            colors = colors.map( color => {
                color.values = color.values.filter( value => {
                    if( !value.alias )
                        value.alias = value.name;
                    
                    if( !value.name || !value.flg_variante_abil )
                        return false;

                    const against =  value.alias.toLowerCase().trim() +
                        value.name.toLowerCase().trim();

                    return against.indexOf( this.state.filter.trim().toLowerCase() ) !== -1;
                });

                return color;
            });
        }

        return colors.filter( color => { return color.values.length > 0; } ) ;
    }

    getColors(){
        let catalog = Object.keys( this.props.option.catalogByGroup ).map( key => {
            if( key == "null" )
                return null;

            return { label: key , gallery: 'big' , 
                values: this.props.option.catalogByGroup[key].filter( color => { return color.in_stock }) };
        });

        catalog = catalog.filter( item => {
            return item != null && item.values.length > 0;
        });

        catalog.push({
            label: 'Altro' , gallery: 'small' , values: this.props.option.values
        });

        return catalog;
    }

    render(){

        let value = undefined;

        let colors = this.getFilteredColors();

        this.props.option.values.map( option => {
            if( option.name == this.props.value )
                value = option.alias;
            return option;
        } );

        const className = 'OptionColorForm ' + 
            ( this.props.value == '' || this.props.value == 'NUL' ? ' OptionGenericSelectForm_AutoCompleteError' : '' );
            
        return <FormInputContainer {...{...this.props, label: 'Colore'}} className={className}>
            <input ref={this.textInput} className="form-control" type="text" onChange={se=>{ this.setState({filter: se.target.value})}}
                value={this.state.filter !== false ? this.state.filter: value} 
                onFocus={se=>{this.setState({focus: true, filter: ''})}}
                onKeyPress={se=>{ if( se.key == 'Enter' ){ this.applyFilteredProduct(); se.preventDefault(); } }}
                onBlur={se=>{ 
                    /*  TIMEOUT PERCHE L EVENTO SI SCATENA ANCHE APPENA SI STA SELEZIONANDO UN COLORE
                        USO QUESTO HACK PER RISOLVERE */
                    setTimeout( () => { this.setState({focus: false, filter: false})} , 250 ) } } />

            { this.state.focus && <div className="OptionColorFormDialog">
                
                {colors.length == 0 && <p style={{ textAlign: 'center' , padding: '3em' , color: 'light-gray', fontStyle: 'italic'}}>
                    Non ci sono colori corrispondenti a questo filtro</p>}
                
                {colors.map( colorGroup => {

                    return <div key={colorGroup.label} className="OptionColorFormDialogArea">
                        <h3>{colorGroup.label}</h3>

                        <Row>
                        {colorGroup.values.map( color => {
                            return <OptionColorFormSingleColor key={color.name} 
                                {...color} gallery={colorGroup.gallery} onClick={v=>{this.onChange(v)}} />;
                        })}
                        </Row>

                    </div>
                })}
            </div> }
        </FormInputContainer>;
    }
}

function OrderCreationOptionsFormsFactory(props) {
    const name = props.option.name.toLowerCase().trim();

    //const value = props.option.values[props.value] ? props.option.values[props.value].alias : false;

    switch(name){
        case 'col': return <OptionColorForm {...props} option={props.option} />;
        case 'mv1': return null;
        case '0': return null;
        default: return <OptionGenericSelectForm {...props} option={props.option} label={props.option.alias}/>;
    }

}

class ProductGallery extends Component {
    render(){
        return <div>
            {this.props.products.map( product => {

            })}
        </div>
    }
}

/**
 * Custom AutoComplete
 * 
 * <AutoCompleteInput 
 *      value="CURRENTVALUE"
 *      items={ FUNCTION | ARRAY OF {label: '' , value: '' } }
 *      onChange={ {label,value} => {} }
 */
class AutoCompleteInput extends Component {
    constructor(props){
        super(props);

        this.state = {
            autoCompleteItems: [],
            autoCompleteSelected: -1,
            autoCompleteVisibile: false,

            searchContent: '',

            isMouseInside: false,
            hasFocus: false,

        };

        this.searchInputRef = React.createRef();

        this.itemClicked = this.itemClicked.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
    }

    componentDidMount(){
        //this.searchInputRef.current.focus();
        this.fetchItems();
    }

    fetchItems(){
        let items = [];

        if( this.props.items ){
            if( typeof( this.props.items ) === 'function' )
                items = this.props.items( this.state.searchContent );

            if( this.props.items.map ){ // Array
                items = this.props.items;
            }
        }

        this.setState({autoCompleteItems: items});
    }

    componentDidUpdate( prevProps , prevState ){

        if( prevProps.value != this.props.value ){
            this.fetchItems();
        }

        if( prevState.hasFocus == false && this.state.hasFocus == true ){
            if( this.props.value )
                this.setState({searchContent: this.currentValueLabel()});
        }

        if( this.state.hasFocus && this.state.autoCompleteVisibile == false ){
            this.fetchItems();
            this.setState({autoCompleteVisibile: true});

            if( this.state.autoCompleteItems.length > 0 ){
                let foundCurrent = 0;
                this.state.autoCompleteItems.map( (item,i) => {
                    if( item.value == this.props.value )
                        foundCurrent = i;
                });
    
                this.setState({autoCompleteSelected: foundCurrent});
            }

        }

        if( this.state.hasFocus == false && this.state.autoCompleteVisibile == true && this.state.isMouseInside == false )
            this.setState({autoCompleteVisibile: false});

        if( prevState.searchContent != this.state.searchContent ){
            this.fetchItems();
        }

        if( this.state.autoCompleteItems.length > 0 && this.state.autoCompleteSelected == -1 )
            this.setState({autoCompleteSelected: 0});

        if( this.state.searchContent.length > 0 && this.state.hasFocus === false && this.state.isMouseInside === false )
            this.clear();
    }

    static getDerivedStateFromProps(props, state){
        return {
            value: props.value
        };
    }

    clear(){
        this.setState({
            /*autoCompleteItems:[], */
            autoCompleteSelected: -1, 
            searchContent: '',
            isMouseInside: false,
            autoCompleteVisibile: false,
        });
    }   

    onBlur(se){
        this.setState({hasFocus: false})
    }

    applyItem( item ){
        console.log( item.label );

        if( this.props.onChange ){
            this.props.onChange( item );
        }

        this.clear();
    }

    applyItemIndex( index ){
        if( index >= 0 && index < this.state.autoCompleteItems.length )
            return this.applyItem( this.state.autoCompleteItems[ index ] );
    }

    onKeyPress(se){
        if( se.key === 'Enter' ){
            se.preventDefault();

            this.applyItemIndex( this.state.autoCompleteSelected );
        }
    }

    itemClicked( itemIndex ){
        this.applyItemIndex( itemIndex );
    }

    currentValueLabel() {
        let inputValue = '';

        if( this.props.value && !this.state.hasFocus ){
            this.state.autoCompleteItems.map( item => {
                if( item.value == this.props.value )
                    inputValue = item.label;
                return item;
            });
        }

        if( this.state.searchContent.length > 0 )
            inputValue = this.state.searchContent;

        return inputValue;
    }

    render(){

        let autoCompleteStyle = {};
        if( this.searchInputRef.current ){
            autoCompleteStyle.width = this.searchInputRef.current.getClientRects()[0].width+'px';
        }

        autoCompleteStyle.display = this.state.autoCompleteVisibile ? 'block' : 'none';

        const itemComponent = this.props.itemComponent ? this.props.itemComponent : 
            (item,onClick,isSelected,index) => {
                const className = isSelected ? 'OrderC_AutoCompleteItems OrderC_AutoCompleteItemsSelected' 
                    : 'OrderC_AutoCompleteItems';

                return <div className={className} onClick={onClick} key={item.value} >
                    {item.label}</div>
            };


        return <FormInputContainer {...this.props}>
            <Row>
                <div className="col-md-12 OrderC_AutoCompleteContainer">
                    <input className="form-control" placeholder={this.props.placeholder}
                        onBlur={this.onBlur}
                        onFocus={se=>{this.setState({hasFocus:true})}}
                        onKeyPress={this.onKeyPress}
                        value={this.currentValueLabel()}
                        ref={this.searchInputRef} 
                        onChange={se=>{this.setState({searchContent: se.target.value})}}
                        {...this.props.inputProps} />
                    <div className="OrderC_AutoCompleteContainerIcon" onClick={se=>{this.searchInputRef.current.focus()}} >
                        <i className="fa fa-chevron-down"></i>
                    </div>

                    <div className="OrderC_AutoComplete" style={autoCompleteStyle} 
                        onMouseEnter={se=>{this.setState({isMouseInside: true});}} 
                        onMouseLeave={se=>{this.setState({isMouseInside: false});}}>

                        {!this.state.autoCompleteItems.length &&
                          <p className={"text-center"} style={{margin: '20px', fontStyle: 'italic'}}>
                              Non ci sono elementi disponibili</p>}

                        {this.state.autoCompleteItems.map( (item,i) => {
                            return itemComponent( item, se => {this.itemClicked(i)}, 
                                this.state.autoCompleteSelected == i , i );
                        })}
                    </div>
                </div>
            </Row>
        </FormInputContainer>;

    }
}

class OrderRowCreationFormProductSelectForm extends Component {
    constructor(props){
        super(props);

        this.fetchItems = this.fetchItems.bind(this);
    }

    componentDidMount(){
        //this.searchInputRef.current.focus();
    }

    fetchItems( queryString ){
        return pwebs().getState().products.filter( product => {
            return product.cda_art_alias.match( new RegExp( queryString , 'i' ) );
        }).filter( product => {
            return !product.isdisabled && product.flg_abil_extranet;
        } ).map( product => {
            return { label: product.cda_art_alias , value: product.cda_art };
        });
    }

    render(){
        return <div className="OrderRowCreationFormProductSelectForm">
            <AutoCompleteInput {...this.props}
                direction={FormInputContainer.HORIZONTAL}
                inputProps={{style:{width: '100%'}}}
                placeholder="Cerca un articolo..."
                label={this.props.hideLabel ? false: "ARTICOLO"}
                items={this.fetchItems} />
        </div>;
    }
}

class OrderRowCreationProductsChooserCustom extends Component {

    constructor(props){
        super(props);

        this.state = {
            products: [],
            categories: [],
            loading: true
        };
    }

    componentDidMount(){
        if( Utils.getUrlParam(-3) != 'edit' ){
            pweb().fetch( 'quotations' , 'products' , 'hitParade' , Utils.getUrlParam(-1), msg => {

                let categories = this.state.categories;

                msg.data.map( product => {
                    if( this.state.categories.indexOf( product.category ) == -1 )
                        categories.push( product.category );
                });

                this.setState( {
                    products: msg.data,
                    categories: categories,
                    loading: false
                } );
            });
        }
    }

    product( alias , boxStyle = {} ){

        const product = this.state.products.filter( p => {
            return p.cda_art_alias == alias;
        })[0];

        return <div className="ibox" onClick={se=>{this.props.onClick(product.cda_art)}}>
                <div className={"ibox-content product-box"} style={boxStyle}>
                    <div className="m-t text-center bold">
                        <strong>{product.cda_art_alias}</strong>
                    </div>
                </div>
            </div>
    }

    render(){

        if( this.state.loading || !this.state.products )
            return <Loader />;

        if( this.state.products.length < 1 )
            return <Loader />;

        return <div className={"OrderRowCreationProductsChooser"}>
            <Row>

                <div className={"col-md-1 CategoryName"}>
                    <span style={{backgroundColor: '#bf94bf'}}>PLISSETTATA</span>

                    <Row>
                        <div className={"col-md-12"}>
                            {this.product('AMBY')}
                            {this.product('SMART')}
                            {this.product('MINIAMBY')}
                            {this.product('VOILA')}
                            {this.product('MINISMART')}
                        </div>
                    </Row>
                </div>

                <div className={"col-md-3 CategoryName"}>
                    <span style={{backgroundColor: '#fcc65d'}}>AVVOLGENTE</span>

                    <Row>
                        <div className={"col-md-4"}>
                            {this.product('DIVA')}
                            {this.product('AMBRA')}
                            {this.product('KITV')}
                            {this.product('SMERALDO')}
                            {this.product('CORALLO')}
                            {this.product('UNIKVER')}
                            {this.product('EVA')}
                            {this.product('ELI')}
                        </div>
                        <div className={"col-md-4"}>
                            {this.product('GIADA')}
                            {this.product('KITL')}
                            {this.product('UNIKLAT')}
                            {this.product('DIAMANTE')}
                            {this.product('RUBINO')}
                            {this.product('UNIKCAT')}
                            {this.product('SARA')}
                            {this.product('SIRIA')}
                        </div>
                        <div className={"col-md-4"}>
                            {this.product('SWITCH4' , { borderColor: 'red'})}
                            {this.product('PERLA')}
                            {this.product('ZAFFIRO')}
                            {/*this.product('UNIKILAT')*/}
                            {this.product('CATY')}
                            {this.product('DIVAKIT')}
                        </div>
                    </Row>
                </div>

                <div className={"col-md-1 CategoryName"}>
                    <span style={{backgroundColor: '#bf94bf'}}>SCORREVOLE</span>

                    <Row>
                        <div className={"col-md-12"}>
                            {this.product('IRIS')}
                            {this.product('NEWDIANA')}
                            {this.product('GHIA')}
                            {this.product('MARGHERITA')}
                            {this.product('ZOE')}
                        </div>
                    </Row>
                </div>

                <div className={"col-md-1 CategoryName"}>
                    <span style={{backgroundColor: '#f09ab2'}}>FISSA</span>

                    <Row>
                        <div className={"col-md-12"}>
                            {this.product('FISSA')}
                        </div>
                    </Row>

                    <span style={{backgroundColor: '#b7c855'}}>BATTENTE</span>

                    <Row>
                        <div className={"col-md-12"}>
                            {this.product('ANTEA')}
                        </div>
                    </Row>
                </div>

                <div className={"col-md-1 CategoryName"}>
                    <span style={{backgroundColor: '#9fb8be'}}>INCASSO</span>

                    <Row>
                        <div className={"col-md-12"}>
                            {this.product('PREGEN')}
                            {this.product('PRESPE')}
                            {this.product('IVER')}
                            {this.product('IDIVA')}
                            {this.product('ISWITCH4', { borderColor: 'red'})}
                            {this.product('ILAT')}
                            {this.product('ICAT')}
                            {this.product('IVOILA')}
                            {this.product('IZAF')}
                            {this.product('ISMART')}
                            {this.product('IAMBY')}
                        </div>
                    </Row>
                </div>
        
               <div className={"col-md-1 CategoryName"}>
                    <span style={{}}>ALTRO</span>

                    <Row>
                        <div className={"col-md-12"}>
                            {this.product('TELO')}
                            {this.product('TELANT')}
                            {this.product('30MON6020')}
                            {this.product('30MON4020')}
                            {this.product('FRONTLOCK')}
                        </div>
                    </Row>

                </div>

            </Row>
        </div>;

    }
}

class OrderRowCreationProductsChooser extends Component {
    constructor(props){
        super(props);

        this.state = {
            products: [],
            categories: [],
            loading: true
        };
/*
        pwebs().subscribe( () => { this.setState( { 
            products: pwebs().getState().products } )} );*/
    }

    componentDidMount(){
        if( Utils.getUrlParam(-3) != 'edit' ){
            pweb().fetch( 'quotations' , 'products' , 'hitParade' , Utils.getUrlParam(-1), msg => {

                let categories = this.state.categories;

                msg.data.map( product => {
                    if( this.state.categories.indexOf( product.category ) == -1 )
                        categories.push( product.category );
                });

                this.setState( {
                    products: msg.data,
                    categories: categories,
                    loading: false
                } );
            });
        }
    }

    getCategoryColor( name ){
        const categoriesColors = {
            "PLISSETTATA": "#bf94bf",
            "AVVOLGENTE": "#fcc65d",
            "INCASSO": "#9fb8be",
            "FISSA":"#f09ab2",
            "SCORREVOLE":"#f09ab2",
            "BATTENTE":"#b7c855",
        };

        let found = false;
        Object.keys(categoriesColors).map( category => {
            if( category.toLowerCase() == name.toLowerCase() )
                found = categoriesColors[category];
        });

        if( !found )
            found = 'inehrit';

        return found;
    }

    render(){

        if( this.state.loading || !this.state.products )
            return <Loader />;

        if( this.state.products.length < 1 )
            return <Loader />;

        const categoriesOrder = [
            [{name:'PLISSETTATA'}],
            [{name:'AVVOLGENTE', items:[
                'DIVA','SWITCH']}],
            [{name:'AVVOLGENTE',startAt: 8}],
            [{name:'AVVOLGENTE',startAt: 15}],
            [{name:'SCORREVOLE'}],
            [{name:'FISSA'},{name:'BATTENTE'}],
            [{name:'INCASSO'}],
            [{name:"Alluminio avvolgente"},{name:"ALTRO"}]
        ];

        return <div className={"OrderRowCreationProductsChooser"}>
            <Row>
                {categoriesOrder.map( oCol => {

                    return <div className={"col-md-1"} style={{width: (100/categoriesOrder.length) + '%'}}>
                            {oCol.map( oRow => {

                                oRow = {
                                    startAt: 0,
                                    endAt: 9999999,
                                    ...oRow
                                };

                                return <div>
                                    {this.state.categories.filter( category => {
                                        return category == oRow.name;
                                    }).map( category => {
                                        if( !category )
                                            return null;

                                        let itemsIndex = 0;

                                        return <Row>
                                            <div className="col-md-12 CategoryName">
                                                <span style={{backgroundColor: this.getCategoryColor(category)}}>{category}</span>
                                            </div>

                                            {this.state.products.filter( product => {
                                                return product.category == category;
                                            }).map( (product,productIndex) => {
                                                if( productIndex < oRow.startAt || productIndex > oRow.endAt )
                                                    return null;

                                                return <div className={"col-lg-12 col-md-12 " + (product.same_order > 0?'SameOrder':'')}
                                                            onClick={se=>{this.props.onClick(product.cda_art)}}>
                                                    <div className="ibox">
                                                        <div className={"ibox-content product-box"}>
                                                            <small className="pull-right">
                                                            </small>

                                                            <div class="m-t text-center bold">
                                                                <strong>{product.cda_art_alias}</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>;
                                            })}
                                        </Row>;
                                    })}
                                </div>;


                            })}
                    </div>;


                })}
            </Row>

            {/* OLD HIT PARADE */ }

            {/*<Row>

                {this.state.categories.map( category => {
                    if( !category )
                        return null;

                    return <div className="col-md-1" style={{width: (100/this.state.categories.length) + '%'}}><Row>
                        <div className="col-md-12 CategoryName">
                            <span style={{backgroundColor: this.getCategoryColor(category)}}>{category}</span>
                        </div>

                        {this.state.products.filter( product => {
                            return product.category == category;
                        }).map( product => {
                            return <div className={"col-lg-12 col-md-12 " + (product.same_order > 0?'SameOrder':'')}
                                onClick={se=>{this.props.onClick(product.cda_art)}}>
                                <div className="ibox">
                                    <div className={"ibox-content product-box"}>
                                        <small className="pull-right">
                                        </small>

                                        <div class="m-t text-center bold">
                                            <strong>{product.cda_art_alias}</strong>    
                                        </div>
                                    </div>
                                </div>
                            </div>;
                        })}
                    </Row></div>;
                })}
            </Row>*/}

        </div>;
    }
}

class OrderRowCreationForm extends Component {

    existingRow=null;

    newRow=false;
    constructor(props){
        super(props);

        this.state = {
            loading: false,
            val_base: 0,
            val_altezza: 0,
            misura_luce_o_finita: 'F',
            quotationCode: this.isNew() ? Utils.getUrlParam(-1) : Utils.getUrlParam(-2),
            products: pwebs().getState().products,
            prg_riga: false,
            cda_art: false,
            des_note: '',
            updating: false,
            globalErrorMessage: false,
            validationErrorMessage: false,
            val_base_1: 0,
            val_base_2: 0,

            ante_changed_manually: false,
            traversa_changed_manually: false,
        };

        pwebs().subscribe( () => { this.setState( { 
            products: pwebs().getState().products } )} );

        this.backToOrder = this.backToOrder.bind(this);

        this.onLocationChangeDetach = Route.onLocationChange( () => {

            if( Utils.getUrlParam( -4 ) != 'rows' )
                return;

            this.setState({
                /*loading: false,*/
                val_base: 0,
                val_altezza: 0,
                products: pwebs().getState().products,
                quotationCode: this.isNew() ? Utils.getUrlParam(-1) : Utils.getUrlParam(-2),
                prg_riga: false,
                cda_art: false,
            });

            if( !this.isNew() && !this.state.loading ){
                this.setState({loading: true});
                pweb().fetch( 'quotations' , 'rows' , 'get' , this.state.quotationCode , Utils.getUrlParam(-1), msg => {
                    this.setState({loading: false});
                    if( !msg.data )
                        return;

                    this.applyData( {
                        data: {
                            ...msg.data,
                            val_altezza: msg.data.misura_luce_o_finita == 'L' ? msg.data.val_altezza_luce : msg.data.val_altezza_finita,
                            val_base: msg.data.misura_luce_o_finita == 'L' ? msg.data.val_base_luce : msg.data.val_base_finita,
                            val_base_1: msg.data.misura_luce_o_finita == 'L' ? msg.data.val_base_1_luce : msg.data.val_base_1_finita,
                            val_base_2: msg.data.misura_luce_o_finita == 'L' ? msg.data.val_base_2_luce : msg.data.val_base_2_finita,
                        }
                    } );
                });
            }
        });

        this.baseInputRef = React.createRef();

        this.onSave = this.onSave.bind(this);

    }

    componentWillUnmount(){
        if( this.onLocationChangeDetach )
            this.onLocationChangeDetach();
    }

    isNew(){
        return Utils.getUrlParam(-2) == 'new';
    }

    applyData( msg ){

        let newState = { ...msg.data,
            /*val_altezza: msg.data.val_altezza_luce,
            val_base: msg.data.val_base_luce,*/
         };

        if( this.state.cda_art && !msg.data.cda_art ){
            console.error(`Server removed cda_art!`);
            console.error(`Current state is`);
            console.error( this.state );
            console.error(`Arrived message is `);
            console.error(msg);
            return;
        }

        newState.globalErrorMessage = false;

        if( newState.options )
            if( newState.options.length < 1 ){
                //newState.globalErrorMessage = 'Questa configurazione non è ordinabile';
                newState.options = this.state.options;
            }            

        if( !this.state )
            return;

        if( !this.state.traversa_changed_manually && msg.data.traversaHint ){
            
            if( newState.options ){
                newState.options = newState.options.map( opt => {
                    if( opt.name == msg.data.traversaHint.cda_tipo_variante )
                        opt.value = '' + msg.data.traversaHint.value;
                    return opt;
                });
            }
        }

        this.setState( newState );

    }

    componentDidMount(){

        pwebs().dispatch( pweb().setHeaderBreadcrumbs([
            {label: t(`Configura articolo`) , url:'#'},
        ]));

        if( !this.isNew() ){
            
            pweb().fetch( 'quotations' , 'rows' , 'get' , this.state.quotationCode , Utils.getUrlParam(-1), msg => {
                this.applyData( {
                    data: {
                        ...msg.data,
                        val_altezza: msg.data.misura_luce_o_finita == 'L' ? msg.data.val_altezza_luce : msg.data.val_altezza_finita,
                        val_base: msg.data.misura_luce_o_finita == 'L' ? msg.data.val_base_luce : msg.data.val_base_finita,
                        val_base_1: msg.data.misura_luce_o_finita == 'L' ? msg.data.val_base_1_luce : msg.data.val_base_1_finita,
                        val_base_2: msg.data.misura_luce_o_finita == 'L' ? msg.data.val_base_2_luce : msg.data.val_base_2_finita,
                    }
                } );
            });
        }
    }

    componentDidUpdate( prevProps, prevState ){

        logComponentDiff( this.state, prevState, this.props , prevProps , 'OrderRowCreationForm' );

        if( prevState.prg_riga < 1 && this.state.prg_riga > 0 && this.state.val_base < 1 ){
            if( this.baseInputRef.current )
                this.baseInputRef.current.focus();
        }

        if( this.state.force_color && !this.state.force_color_done )
            this.onOptionChange( { name: 'COL' } , this.state.force_color );

    }

    delayedSave( payload , cb ){

        if( this.saveRequested ){
            clearTimeout( this.saveRequested.handle );
        }

        if( this.saveRequestedFetch )
            this.saveRequestedFetch.abort();

        let data = { t: (new Date()).getTime() , cb: cb };

        data.handle = setTimeout( () => {

            this.saveRequestedFetch = pweb().fetch( 'quotations' , 'rows' , 'save' , payload , msg => {
                cb( msg );
            });
            this.saveRequested = undefined;
        } , 1000 );

        this.saveRequested = data;

    }

    setStateAndSave( newState ){

        //newState.validationErrorMessage = this.validate();

        if( newState.val_base ){
            if( newState.val_base != this.state.val_base /*&& 
                newState.val_base >= this.state.val_base_min && 
                newState.val_base <= this.state.val_base_max*/ ){

                const half = Math.ceil( newState.val_base / 2 );
                newState.val_base_1 = half;
                newState.val_base_2 = half;
            }
        } else if( newState.val_base_1 ){
            if( newState.val_base_1 != this.state.val_base_1 ){
                newState.val_base_2 = this.state.val_base - newState.val_base_1;
            }
        } else if( newState.val_base_2 ){
            if( newState.val_base_2 != this.state.val_base_2 ){
                newState.val_base_1 = this.state.val_base - newState.val_base_2;
            }
        }

        this.setState( {...newState, updating: true} );

        let payload = Object.assign({}, {...this.state,...newState});

        delete payload.products;

        this.delayedSave( payload , msg => {
            this.applyData( msg );
            this.setState({updating: false/*, validationErrorMessage: this.validate()*/});
        });

    }

    onOptionChange(option, value ){

        let options = {};

        if( this.state.options )
            options = this.state.options;


        if( option ){

            options[option.name] = value;

            if( option.name == 'ANT' || option.name == 'ANA' )
                this.setState({ante_changed_manually: true});

            if( ['TRF'].indexOf( option.name ) != -1 ){
                this.setState({traversa_changed_manually: true});
            }
                
        }

        this.setStateAndSave( {
            options: options,
            force_color_done: true,
            validationErrorMessage: false} );
    }

    canSave(){
        return (this.state.val_movim_netto > 1 || this.state.flg_preventivo > 0) 
            && !this.state.updating 
            && ((this.state.flg_preventivo > 0 == this.state.quotation_flg_preventivo > 0 ||
                this.state.quotation_row_count < 1))
    }

    onSave(se){
        se.preventDefault();

        if( !this.canSave() )
            return;

        const validationMessage = this.validate();

        this.setState({validationErrorMessage: validationMessage});

        if( validationMessage !== false )
            return;
        
        this.setState({updating: true});
        this.existingRow=null; 
     
        pweb().fetch('quotations','rows','checkPreviousOrders',{cda_art:this.state.cda_art,prg_doc:this.state.prg_doc,prg_art:this.state.prg_art,val_altezza:this.state.val_altezza,val_base:this.state.val_base,options:this.state.options}, (msg)=>{
            if(msg.status){
                this.existingRow={data:msg.data,se:se,state:this.state.des_stato,fx:()=>{
                    pweb().fetch( 'quotations' , 'rows', 'confirm' , OrderCreationUtils.packCode( this.state ), this.state.prg_riga , msg => {
                        this.setState({updating: false});
                        this.backToOrder(se);
                    });
                }} 
                console.log(msg.data);
                msg.data.rows=msg.data.rows.filter((row)=>{
                    return row.CDA_ART==this.state.cda_art && 
                           row.VAL_ALTEZZA== parseFloat(this.state.val_altezza) &&
                           row.VAL_BASE == parseFloat(this.state.val_base) &&
                           row.DES_VARIANTI_VIS==this.state.options
                }) 
           
             
                this.setState({updating: false});
            }
            else{
                pweb().fetch( 'quotations' , 'rows', 'confirm' , OrderCreationUtils.packCode( this.state ), this.state.prg_riga , msg => {   
                    if( msg.status && !this.existingRow )
                        this.backToOrder(se);
                });
            }
         
        })

    }

    backToOrder(se){
        se.preventDefault();
        Route.pushLocation('/orderc/edit/' + OrderCreationUtils.packCode( this.state ));
    }
 
    validate( name = false ){

        let validation = {
            'val_altezza': ()=>{
                    return this.state.val_altezza<this.state.val_altezza_min||this.state.val_altezza>this.state.val_altezza_max ? 
                        `L'altezza deve essere compresa tra ${this.state.val_altezza_min} e ${this.state.val_altezza_max}` : false;
            },
            'val_base': ()=>{
                    return this.state.val_base<this.state.val_base_min||this.state.val_base>this.state.val_base_max ? 
                        `La base deve essere compresa tra ${this.state.val_base_min} e ${this.state.val_base_max}` : false;
            },
            'qta_pezzi': ()=> {
                return this.state.qta_pezzi < 1 ? 'Pezzi non validi' : false;
            },
            'option_nul': ()=> {
                let valid = true;
                Object.keys( this.state.options ).map( key => {
                    if( this.state.options[key] =='NUL' )
                        valid = false;
                });

                return valid ? false : 'Specificare tutte le opzioni richieste';
            }
        }

        if( ! ( [ 'MQ' ].indexOf( this.state.cda_unimis_doc ) !== -1 || ['IVOILA','VOILA18','TEL4020','TEL6020'].indexOf( this.state.cda_art ) !== -1 ) ){
            delete validation['val_altezza'];
            delete validation['val_base'];
        }

        if( name ){

            if(!validation[name])
                return false;

            return validation[name]();

        } else {

            const keys = Object.keys(validation);
            for( let i=0;i<keys.length;i++){
                let ret = validation[keys[i]]();
                if( ret !== false )
                    return ret;
            }

            return false;
        }

    }

    render(){ 
        if( this.state.loading || !this.state.products )
            return <Loader />;

        if( this.state.products.length < 1 )
            return <Loader />;

        if( this.state.prg_riga < 1 && this.state.cda_art )
            return <Loader />;

        let productSelectOption = { '': false };
        Object.keys(this.state.products).map( productKey => {
            productSelectOption[this.state.products[productKey].cda_art] = 
                this.state.products[productKey].cda_art_alias;
        });

        const product = this.state.cda_art ? pweb().getProduct( this.state.cda_art ) : false;

        let optionValues = {};
        let has_2_ante = false;

        let traversa = false || this.state.prg_art_traversa;

        /*if( this.state.options )
            this.state.options.map( opt => {

                if( opt.name == 'ANA' || opt.name == 'ANT' ){
                    has_2_ante = opt.value == '2';
                }

                optionValues[opt.name] = opt.value;

                return opt;
            });*/

        if( this.state.options ){
            Object.keys( this.state.options ).map( key => {
                if( key == 'ANA' || key == 'ANT' ){
                    has_2_ante = this.state.options[key] == '2' || this.state.options[key] == '2SP';
                }

                optionValues[key] = this.state.options[key];

                return this.state.options[key];
            })
        }

        let saveLabel = 'Salva';        
        if( this.state.updating )
            saveLabel = [ <i className="fa fa-circle-notch fa-spin"></i>, <i>Aggiornamento dati</i> ];
        else if( !this.canSave() ){
            saveLabel = [ <i className="fa fa-exclamation"></i>, <i>Verificare dati</i> ];
        }

        saveLabel = <span style={{minWidth: '170px',display:'inline-block'}}>
            {saveLabel}</span>;

        // ( AVVOLGENTI OR PLISSETATE ) AND ( ANT = 2 OR ANA = 2 )
        // LA GHIA HA LE ANTE VERTICALI QUINDI NON PUO ESSERE ASIMMETRICA
        const asymmetric = ( has_2_ante || ['AVVOLGENTI', 'PLISSETTATE'].indexOf( product.category ) != -1 ) && product.cda_art != 'GHIA' ;

        const saveButton = <FormButtons onSave={this.onSave}
            direction={FormInputContainer.HORIZONTAL}
            saveLabel={saveLabel}
            saveEnable={this.canSave()}
            
            onCancel={se=>{this.backToOrder(se)}} />;
    
        if(this.existingRow){
            return <div className="SweetAlert">

                        <div className="SweetAlertBg" ></div>

                        <div className="SweetAlertBox" style={{marginTop: '30px', width: '400px', marginLeft: '-140px',border:'solid',borderColor:'red' }}>
                            <div>
                                <div>
                                <svg height="40px" width="40px" version="1.1" id="Layer_1" viewBox="0 0 507.425 507.425"  >
                                <path style={{fill:'#FFC52F'}}   d="M329.312,129.112l13.6,22l150.8,242.4c22.4,36,6,65.2-36.8,65.2h-406.4c-42.4,0-59.2-29.6-36.8-65.6
                                    l198.8-320.8c22.4-36,58.8-36,81.2,0L329.312,129.112z"/>
                                <g>
                                    <path  style={{fill:'#F4EFEF'}}   d="M253.712,343.512c-10.8,0-20-8.8-20-20v-143.2c0-10.8,9.2-20,20-20s20,8.8,20,20v143.2
                                        C273.712,334.312,264.512,343.512,253.712,343.512z"/>
                                    <path style={{fill:'#F4EFEF'}}   d="M253.312,407.112c-5.2,0-10.4-2-14-6c-3.6-3.6-6-8.8-6-14s2-10.4,6-14c3.6-3.6,8.8-6,14-6
                                        s10.4,2,14,6c3.6,3.6,6,8.8,6,14s-2,10.4-6,14C263.712,404.712,258.512,407.112,253.312,407.112z"/>
                                </g>
                                <path d="M456.912,465.512h-406.4c-22,0-38.4-7.6-46-21.6s-5.6-32.8,6-51.2l198.8-321.6c11.6-18.8,27.2-29.2,44.4-29.2l0,0
                                    c16.8,0,32.4,10,43.6,28.4l35.2,56.4l0,0l13.6,22l150.8,243.6c11.6,18.4,13.6,37.2,6,51.2
                                    C495.312,457.912,478.912,465.512,456.912,465.512z M253.312,49.912L253.312,49.912c-14,0-27.2,8.8-37.6,25.2l-198.8,321.6
                                    c-10,16-12,31.6-5.6,43.2s20.4,17.6,39.2,17.6h406.4c18.8,0,32.8-6.4,39.2-17.6c6.4-11.2,4.4-27.2-5.6-43.2l-150.8-243.6l-13.6-22
                                    l-35.2-56.4C280.512,58.712,267.312,49.912,253.312,49.912z"/>
                                <path d="M249.712,347.512c-13.2,0-24-10.8-24-24v-143.2c0-13.2,10.8-24,24-24s24,10.8,24,24v143.2
                                    C273.712,336.712,262.912,347.512,249.712,347.512z M249.712,164.312c-8.8,0-16,7.2-16,16v143.2c0,8.8,7.2,16,16,16s16-7.2,16-16
                                    v-143.2C265.712,171.512,258.512,164.312,249.712,164.312z"/>
                                <path d="M249.712,411.112L249.712,411.112c-6.4,0-12.4-2.4-16.8-6.8c-4.4-4.4-6.8-10.8-6.8-16.8c0-6.4,2.4-12.4,6.8-16.8
                                    c4.4-4.4,10.8-7.2,16.8-7.2c6.4,0,12.4,2.4,16.8,7.2c4.4,4.4,7.2,10.4,7.2,16.8s-2.4,12.4-7.2,16.8
                                    C262.112,408.312,256.112,411.112,249.712,411.112z M249.712,371.112c-4,0-8.4,1.6-11.2,4.8c-2.8,2.8-4.8,7.2-4.8,11.2
                                    c0,4.4,1.6,8.4,4.8,11.2c2.8,2.8,7.2,4.8,11.2,4.8s8.4-1.6,11.2-4.8c2.8-2.8,4.8-7.2,4.8-11.2s-1.6-8.4-4.8-11.2
                                    C258.112,372.712,253.712,371.112,249.712,371.112z"/>
                                </svg>
                                </div>
                                <div>
                                    <div>Ciao, sono ADA l’intelligenza artificiale di PRIMED.</div>
                                    <div>Questa zanzariera sembra già essere stata ordinata,</div>
                                    <div>riporto qui di seguito i riferimenti dell’ordine che ho trovato:</div>
                                    <div style={{display:'flex',justifyContent:'center'}}>
                                        <div style={{textAlign:'left',marginTop:'10px',marginBottom:'10px'}}>
                                            <div style={{display:'flex',flexDirection:'row'}}>- DATA ORDINE:   <div style={{fontWeight:'bold',marginLeft:'5px'}}> {(new Date(this.existingRow.data.DAT_DOC)).toLocaleDateString()} </div></div>
                                            <div style={{display:'flex',flexDirection:'row'}}>- NUMERO ORDINE:  <div style={{fontWeight:'bold',marginLeft:'5px'}}> {this.existingRow.data.CDA_SERIE_NUM+' '+this.existingRow.data.PRG_DOC}</div></div>
                                            <div style={{display:'flex',flexDirection:'row'}}>- STATO DELL'ORDINE:   <div style={{fontWeight:'bold',marginLeft:'5px'}}> 
                                                {this.existingRow.data.CDA_STATO_DOC =='INS' &&'INSERITO'   } 
                                                {this.existingRow.data.CDA_STATO_DOC =='LAV' &&'IN LAVORAZIONE'   }
                                                {this.existingRow.data.CDA_STATO_DOC =='PRO' &&'PRODOTTO'   }
                                                {this.existingRow.data.CDA_STATO_DOC =='EVA' &&'EVASO'   }
                                            </div></div>
                                            <div style={{display:'flex',flexDirection:'row'}}>- DATA PARTENZA:   <div style={{fontWeight:'bold',marginLeft:'5px'}}> {(new Date(this.existingRow.data.DAT_CONS_RICH)).toLocaleDateString()} </div></div>
                                            <div style={{display:'flex',flexDirection:'row'}}>- RIFERIMENTO:  <div style={{fontWeight:'bold',marginLeft:'5px'}}>   {this.existingRow.data.DES_RIFERIMENTO} </div></div>
                                        </div>
                                    </div>
                                    <div>
                                            <button className="btn btn-primary" onClick={ (se)=>{     this.existingRow.fx(); }} style={{marginLeft: '10px'}} >{this.existingRow.state=='draft'?'INSERISCI':'CONFERMA MODIFICA'}</button>   
                                            <button className="btn" onClick={ (se)=>{   this.backToOrder(this.existingRow.se); }} style={{marginLeft: '10px'}} >{this.existingRow.state=='draft'?'ELIMINA RIGA':'ANNULLA MODIFICA'}</button>    
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>;
         }

        const unimis = this.state.display_unimis_doc||this.state.cda_unimis_doc;

        return  <Row className="OrderRowCreationForm"> 
            <div className="col-lg-12">
            <form className="form-inline" onSubmit={this.onSave} >

                <BoxContainer title="Dati articolo" tools={
                    <OrderRowCreationFormProductSelectForm
                        onChange={se=>{this.setStateAndSave({cda_art: se.value, val_altezza: 0, val_base: 0, product_prg: 0})}}
                        hideLabel={true}
                        value={this.state.cda_art} />
                }>
                    
                        { /* <Row style={{marginBottom: '10px'}}>
                            <div className="col-md-12 ProdcutChooser">



                            </div>

                        </Row> */ }

                        { !product && <React.Fragment>
                            <OrderRowCreationProductsChooserCustom
                                onClick={cda_art => {
                                    this.setStateAndSave({cda_art: cda_art,
                                        val_altezza: 0, val_base: 0})}}/>
                             </React.Fragment> }
                        
                        { this.state.cda_art && product &&  <Row>

                                    
                            <FormInputContainer className="OrderRowCF_Checks" direction={FormInputContainer.HORIZONTAL} label="TIPO MISURA">
                                <FormInputRadio label="Luce" checked={this.state.misura_luce_o_finita == 'L' }
                                    onClick={se=>{ this.setStateAndSave({misura_luce_o_finita: 'L'})}} />

                                <FormInputRadio label="Finita" checked={this.state.misura_luce_o_finita == 'F' }
                                    onClick={se=>{ this.setStateAndSave({misura_luce_o_finita: 'F'})}} />
                            </FormInputContainer>

                            <FormInputText label={t('PEZZI')} value={this.state.qta_pezzi}
                                direction={FormInputContainer.HORIZONTAL}
                                error={this.validate('qta_pezzi')}
                                /*errorLabel={this.validate('qta_pezzi')}*/
                                inputProps={{maxlength:2, style:{maxWidth: '45pt'}}}
                                onChange={se=>{this.setStateAndSave({qta_pezzi:se.target.value})}} />
                        
                        { ( [ 'MQ' ].indexOf( unimis ) !== -1 || ['IVOILA','VOILA18','TEL4020','TEL6020', 'DIVAKIT','FRONTLOCK','TEL6020','TEL4020'].indexOf( this.state.cda_art ) !== -1 ) && [

                            <FormInputText label={t('BASE (MM)')} value={isNaN( this.state.val_base ) || this.state.val_base == 0 ? '' : this.state.val_base} 
                                direction={FormInputContainer.HORIZONTAL}
                                ref={this.baseInputRef}
                                error={this.validate('val_base')}
                                /*errorLabel={this.validate('val_base')}*/
                                inputProps={{maxlength:4, style:{maxWidth: '70pt'}}}
                                onChange={se=>{this.setStateAndSave({val_base:parseFloat(se.target.value)})}} />
                        ,
                            <FormInputText label={t('ALTEZZA (MM)')} value={isNaN( this.state.val_altezza ) || this.state.val_altezza == 0 ? '' : this.state.val_altezza} 
                                direction={FormInputContainer.HORIZONTAL}
                                error={this.validate('val_altezza')}
                                /*errorLabel={this.validate('val_altezza')}*/
                                inputProps={{maxlength:4, style:{maxWidth: '70pt'}}}
                                onChange={se=>{this.setStateAndSave({val_altezza:se.target.value})}} />

                        ] }

                        { [ 'ML','B','H' ].indexOf( unimis ) !== -1 && (['FRONTLOCK','TEL6020','TEL4020'].indexOf( this.state.cda_art )  == -1 ) && [
                            <FormInputText label={t(unimis == "ML" ? 'LUNGHEZZA (MM)' : ( unimis == 'B' ? 'BASE (MM)' : 'ALTEZZA (MM)') )}
                                value={isNaN( this.state.val_base ) || this.state.val_base == 0 ? '' : this.state.val_base}
                                direction={FormInputContainer.HORIZONTAL}
                                ref={this.baseInputRef}
                                error={this.validate('val_base')}
                                /*errorLabel={this.validate('val_base')}*/
                                inputProps={{maxlength:4, style:{maxWidth: '70pt'}}}
                                onChange={se=>{this.setStateAndSave({val_base:parseFloat(se.target.value), val_altezza: 1})}} />
                        ] }
                        
                        { asymmetric && 
                            [ <FormInputText label={t('BASE SX (MM)')} value={isNaN( this.state.val_base_1 ) || this.state.val_base_1 == 0 ? '' : this.state.val_base_1} 
                                direction={FormInputContainer.HORIZONTAL}
                                error={this.validate('val_base_1')}
                                /*errorLabel={this.validate('val_base')}*/
                                inputProps={{maxlength:4, style:{maxWidth: '70pt'}}}
                                onChange={se=>{this.setStateAndSave({val_base_1:parseFloat(se.target.value)})}} />
                            ,
                            <FormInputText label={t('BASE DX (MM)')} value={isNaN( this.state.val_base_2 ) || this.state.val_base_2 == 0 ? '' : this.state.val_base_2} 
                                direction={FormInputContainer.HORIZONTAL}
                                error={this.validate('val_base_2')}
                                /*errorLabel={this.validate('val_base')}*/
                                inputProps={{maxlength:4, style:{maxWidth: '70pt'}}}
                                onChange={se=>{this.setStateAndSave({val_base_2:parseFloat(se.target.value)})}} />
                            ]
                        }

                            <FormInputText label={t('VOSTRO RIFERIMENTO')} value={this.state.des_subcliente}
                                direction={FormInputContainer.HORIZONTAL}
                                inputProps={{maxlength:25, style:{width: '190pt'}}}
                                onChange={se=>{this.setStateAndSave({des_subcliente:se.target.value})}} />
                                
                            <FormInputText label={t('STANZA')} value={this.state.des_stanza}
                                direction={FormInputContainer.HORIZONTAL}
                                inputProps={{maxlength:25, style:{width: '190pt'}}}
                                onChange={se=>{this.setStateAndSave({des_stanza:se.target.value})}} />
                            </Row>}
                            

                        { (this.state.cda_art && product) &&  <Row>
                                    
                            {product.options.map( option => {
                                return <OrderCreationOptionsFormsFactory option={option} 
                                    product={product}
                                    direction={FormInputContainer.HORIZONTAL}
                                    value={optionValues[option.name]}
                                    onChange={se=>{ this.onOptionChange(option, se)}} />
                            })}

                            {(this.state.globalErrorMessage || this.state.validationErrorMessage) && <div className="col-md-12">
                                <p className="text-center" style={{color: 'red', textDecoration: 'italic', fontSize: '18pt'}}>
                                    {this.state.globalErrorMessage !== false ? 
                                        this.state.globalErrorMessage : this.state.validationErrorMessage}</p>
                            </div>}

                            <div className="col-md-12">
                                {this.state.product_prg_art_error_message && 
                                    <p className="text-center" style={{color: 'red', textDecoration: 'italic', fontSize: '18pt'}}>
                                        {this.state.product_prg_art_error_message}</p>}
                            </div>


                            {(traversa?true:false) && <React.Fragment>
                                                                    
                                <FormInputSelect label={t('Rif. Tra')} value={this.state.cda_traversa}
                                    style={{minWidth:'150px'}} direction={FormInputContainer.HORIZONTAL}
                                    onChange={se=>{this.setStateAndSave({cda_traversa: se, flg_traversa_mod: 1})}} >

                                    <FormInputSelectOption label={t('SOPRA TRAVERSA')} value="+" />
                                    <FormInputSelectOption label={t('CENTRO TRAVERSA')} value="c" />
                                    <FormInputSelectOption label={t('SOTTO TRAVERSA')} value="-" />

                                </FormInputSelect>

                                <FormInputText label={t('POSIZIONE TRAVERSA (MM)')} value={isNaN( this.state.val_traversa ) || this.state.val_traversa == 0 ? '' : this.state.val_traversa} 
                                    direction={FormInputContainer.HORIZONTAL}
                                    error={this.validate('val_traversa')}
                                    inputProps={{maxlength:4, style:{maxWidth: '70pt'}}}
                                    onChange={se=>{this.setStateAndSave({
                                        val_traversa:parseFloat(se.target.value),
                                        flg_traversa_mod: 1
                                    })}} />
                            </React.Fragment>}

                            <FormInputText label={t('NOTE BLOCCANTI PER LA PRODUZIONE')} value={this.state.des_note}
                                direction={FormInputContainer.HORIZONTAL}
                                inputProps={{maxlength:100, style:{width: '300pt'}}}
                                placeholder={t('Non inserire vostro riferimento')}
                                onChange={se=>{this.setStateAndSave({
                                    des_note: se.target.value
                                }) 
                            }} />
                            {this.state.des_note &&
                            <div style={{color: 'red',fontWeight:'bold',marginTop:'-20px',marginLeft:'10px'}}>
                                **QUESTA NOTA BLOCCHERÀ L’ORDINE PER UNA REVISIONE MANUALE DA PARTE DI UN OPERATORE**
                            </div>}

                        </Row>}


                    

                    </BoxContainer>

                    {this.state.flg_preventivo > 0 && <Row>
                        <div className="col-lg-8 col-lg-offset-2">
                            <div className="alert alert-warning">
                                <i className="fa fa-exclamation-triangle fa-2x" style={{
                                    float: 'left',
                                    marginRight: '15px'
                                }}></i>
                                <h4>Gli articoli inseriti non rientrano tra i nostri colori in cartella.
                                     Provvederemo ad inviarvi il preventivo aggiornato con la relativa maggiorazione.</h4>
                            </div>
                        </div>
                    </Row>}

                    { (this.state.flg_preventivo > 0 !== this.state.quotation_flg_preventivo > 0 &&
                        this.state.quotation_row_count > 0) && <Row>

                        <div className="col-lg-8 col-lg-offset-2">
                            <div className="alert alert-danger">
                                <i className="fa fa-exclamation-triangle fa-2x" style={{
                                    float: 'left',
                                    marginRight: '15px'
                                }}></i>
                                <h4>Al momento non è possibile unire in un unico ordine articoli
                                     con colore in cartella e colori speciali. 
                                     La invitiamo a inserire un nuovo ordine o una nuova
                                      richiesta di preventivo separando gli articoli in questione.</h4>
                            </div>
                        </div>

                    </Row> }

                    { this.state.cda_art && product && <Row>
                        <div className="col-md-12">

                            <Row>
                                <div className="col-lg-4 col-md-6">
                                    <OrderRowCreationFormTotals flg_preventivo={this.state.flg_preventivo}
                                        net_unimis={this.state.val_prz_unimis_listino}
                                        label="Prezzo di listino" net={this.state.val_movim_netto_listino} 
                                        vat={this.state.val_movim_iva_listino} total={this.state.val_movim_lordo_listino}
                                        unimis={this.state.cda_unimis_doc} qta={this.state.qta_unimis_doc} />
                                </div>

                                <div className="col-lg-4 text-center visible-lg-block" style={{paddingTop: '70px'}}>
                                    {saveButton}
                                </div>
                                
                                <div className="col-lg-4 col-md-6">
                                    <OrderRowCreationFormTotals flg_preventivo={this.state.flg_preventivo}
                                        net_unimis={this.state.val_prz_unimis}
                                        label="Prezzo a lei riservato" net={this.state.val_movim_netto} 
                                        vat={this.state.val_movim_iva} total={this.state.val_movim_lordo}
                                        unimis={this.state.cda_unimis_doc} qta={this.state.qta_unimis_doc} />
                                </div>
                            </Row>

                            <Row>
                                <div className="col-md-12 text-center hidden-lg" style={{paddingTop: '0'}}>
                                    {saveButton}
                                </div>
                            </Row>

                        </div>

                    </Row> }

                </form>

            </div>
            
        </Row>; 
    }

}

function OrderRowCreationFormTotals(props){

    if( props.flg_preventivo ){
        return null;
    }

    return <BoxContainer title={props.label}>
        { /* <h3 className="text-center">{props.label}</h3> */}
        <table className="table">
            <thead>
                <tr>
                    { props.unimis ? <th className="text-right">Prezzo/{props.unimis}</th> : null }
                    { props.unimis ? <th className="text-center">{props.unimis}</th> : null }
                    <th className="text-right">Imponibile</th>
                    <th className="text-right">Iva</th>
                    <th className="text-right" style={{backgroundColor:'#e42727',color:'white'}}>Totale</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    { props.unimis ? <td className="text-right">{Utils.formatCurrency( props.net_unimis )}</td> : null }
                    { props.unimis ? <td className="text-center">{parseFloat(props.qta).toFixed(2)}</td> : null }
                    <td className="text-right">{Utils.formatCurrency( props.net )}</td>
                    <td className="text-right">{Utils.formatCurrency( props.vat )}</td>
                    <td className="text-right" style={{backgroundColor:'#e42727',color:'white'}}>{Utils.formatCurrency( props.total )}</td>
                </tr>
            </tbody>
        </table>
    </BoxContainer>;
}

class OrderShippingForm extends Component {
    constructor(props){
        super(props);

        this.state = {
            loading: false,
            searchText: '',
            ...pwebs().getState()
        };

        pwebs().subscribe( () => { 
            if( pwebs().getState().userData ) 
                this.setState( pwebs().getState().userData ); 
        } );

        this.applyDm = this.applyDm.bind( this );
    }

    applyDm( addressKey ){

        this.setState({loading: true});

        pweb().fetch( 'quotations' , 'get' ,Utils.getUrlParam(-1) , quotation => {

            if( quotation.status ){

                quotation = quotation.data;

                pweb().fetch( 'quotations' , 'save' , { prg_doc:quotation.prg_doc, 
                    num_eser_cont: quotation.num_eser_cont, cdn_dm: addressKey } , msg => {
                    Route.pushLocation('/orderc/edit/' + Utils.getUrlParam(-1) );
                });
                
            }
            
        });

    }

    componentDidMount(){
        /*pwebs().dispatch( pweb().setHeaderBreadcrumbs([
            {label: 'Utente ' + pwebs().getState().userData.cda_ana , url:'/user/edit'},
            {label: t('Shipping addresses'), url:'#'},
        ]));*/
    }

    render(){

        if( this.state.loading )
            return <Loader />;

        let shipping_addresses = this.state.userData.shipping_addresses;

        if( this.state.searchText.trim().length > 0 ){
            shipping_addresses = {};
            const searchText = this.state.searchText.toLowerCase().trim();

            Object.keys(this.state.userData.shipping_addresses).map( address_key => {
                const address = this.state.userData.shipping_addresses[address_key];

                const props_to_search_in = [ 'des_dm' , 'des_indir' , 'des_loc' , 
                    'des_tel' , 'des_telex' ];

                props_to_search_in.map( prop => {
                    if( !address[prop] )
                        return;

                    if( address[prop].toLowerCase().indexOf( searchText ) >= 0 )
                        shipping_addresses[address_key] = address;
                });

            });
        }

        return <Row id="shipping_address"> 

            <div className="col-md-12">
                <Row>
                    <div className="col-md-8 col-md-offset-2 col-xs-12 col-lg-6 col-lg-offset-3">
                        <FormInputText label="Filtro" value={this.state.searchText} onChange={e=>{}}
                            direction={FormInputContainer.HORIZONTAL}
                            onChange={se=>{this.setState({searchText:se.target.value})}} />
                    </div>
                </Row>
            </div>
            
            {Object.keys(shipping_addresses).map( addressKey => {
                return <div className="col-md-3" style={{minHeight: '230px'}}>
                    <div class="ibox" onClick={se=>{ this.applyDm( addressKey ) }}>
                        <div class="ibox-content product-box">

                            <div class="product-desc">

                                <ShippingAddress cdn_dm={addressKey} style={{minHeight: 'auto'}} />

                                <div class="m-t text-righ">

                                    <a href="#" class="btn btn-xs btn-outline btn-primary">Usa questo indirizzo <i class="fa fa-long-arrow-right"></i> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>;

            } ) }
        </Row>;
    }
}

class OrderCreationBase extends Component {

    componentDidMount(){
        pwebs().dispatch( pweb().setSearchVisibility( false ) );
    }

    componentWillUnmount(){
        pwebs().dispatch( pweb().setSearchVisibility( true ) );
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        logComponentDiff( this.state, prevState, this.props , prevProps , 'OrderCreationBase' );
    }

    render(){
        return <div>
            <Route url="/orderc/index"><OrderCreationIndex /></Route>
            <Route url="/orderc/edit"><OrderCreationForm /></Route>
            <Route url="/orderc/new"><OrderCreationForm /></Route>

            <Route url="/orderc/rows/edit"><OrderRowCreationForm /></Route>
            <Route url="/orderc/rows/new"><OrderRowCreationForm /></Route>

            <Route url="/orderc/dm/"><OrderShippingForm /></Route>
            
            <Route url="/orderc/rows/choice">
                <ProductChoiceForm onProductSelected={product=>{
                    Route.pushLocation( `/orderc/rows/new/${product.cda_art}/${Utils.getUrlParam(-1)}`)
                }} />
            </Route>
        </div>;
    }
}

class PrintModal extends Component {

    constructor(props){
        super(props);

        this.state = {
            visible: true,
            logo_enable: "1",
            price_type_reserved_logo_1: "1",
            price_type_reserved_logo_0: "1",
            price_type_reserved: "1",
            discount_value: 0,
            discount_value_list: 0,
            discount_value_res: 0,
            copy_email: '',
            discount_value_error: false,
            business_name: '',
            address : '' ,
            cap : '',
            city: '',
            province : '',
            telephone: '',
            email: '',
            updating: true,
            print: false,
            send: 0, // 0 (Ready) , 1 (Sending), 2 (Complete)
        };

        /*this.dynamicValues().map( v => {
            this.state[v] = this.props[v];
        });*/

        this.loadData();

        this.close = this.close.bind( this );
    }

    loadData(){
        this.setState({updating: true});

        pweb().fetch( 'print' , 'get' , this.state , msg => {

            let stateChange = {
                updating: false,
            };

            this.dynamicValues().map( name => {
                stateChange[name] = this.state[name];
            });

            this.setState( stateChange );
        });
    }

    async _saveData(){
        return new Promise( success => {
            pweb().fetch( 'print' , 'save' , this.state , msg => {
                success();
            });
        });
    }

    close( se ){

        se.preventDefault();

        if( !this.props.onClose )
            return;

        this.props.onClose( this.state );
    }

    static getDerivedStateFromProps(props, state){
        return {
            num_eser_cont: props.num_eser_cont,
            prg_doc: props.prg_doc,
            cda_serie_num: props.cda_serie_num,
            cda_polo_num: props.cda_polo_num,
            cda_tipo_doc_num: props.cda_tipo_doc_num,
            visible: props.visible,
        };
    }

    dynamicValues(){
        return [
            'logo_enable',
            'discount_value_list' , 'discount_value_res',
            'price_type_reserved',
            'copy_email',
            'business_name',
            'address',
            'cap',
            'city',
            'province',
            'telephone',
            'email',
        ];
    }

    componentDidUpdate( prevProps, prevState ){

        if( prevState.visible && !this.state.visible )
            this.setState({send: 0});

        if( prevState.send == 0 && this.state.send == 1 ){

            let payload = {
                num_eser_cont: this.state.num_eser_cont,
                prg_doc: this.state.prg_doc,
                cda_serie_num: this.state.cda_serie_num,
                cda_polo_num: this.state.cda_polo_num,
                cda_tipo_doc_num: this.state.cda_tipo_doc_num,
            };

            this.dynamicValues().map( name => {
                payload[name] = this.state[name];
            });

            pweb().fetch( 'print' , 'save' , payload , msg => {
                //this.setState( { updating: false } );

                const fullPackedCode = [ this.state.num_eser_cont , this.state.cda_serie_num , this.state.cda_tipo_doc_num ,
                    this.state.prg_doc , this.state.cda_polo_num ].join( '-' );

                pweb().fetch( 'print' , 'email' , fullPackedCode , this.state.logo_enable , this.state.price_type_reserved , this.state.discount_value , msg => {
                    this.setState( { send: 2 } );
                });
            });
        }
    
        if( !prevState.print && this.state.print ){

            this.setState({discount_value_error: false});

            const dv = parseInt( this.state.discount_value );
            if( !isNaN( dv ) ){

                this.setState( { updating: true } );

                let payload = {
                    num_eser_cont: this.state.num_eser_cont,
                    prg_doc: this.state.prg_doc,
                    cda_serie_num: this.state.cda_serie_num,
                    cda_polo_num: this.state.cda_polo_num,
                    cda_tipo_doc_num: this.state.cda_tipo_doc_num,
                };

                this.dynamicValues().map( name => {
                    payload[name] = this.state[name];
                });

                pweb().fetch( 'print' , 'save' , payload , msg => {
                    this.setState( { updating: false } );
                    document.getElementById("printform").submit();
                });

                //this.props.onClose();
            } else {
                this.setState({discount_value: 0, discount_value_error: true});
            }

            this.setState({print:false});
        }

        this.dynamicValues().map( v => {
            if( this.props[v] != prevProps[v] ){
                this.setState({[v]: this.props[v]});
            }
        });

        if( this.state.logo_enable == "1" ){
            if( this.state.price_type_reserved != this.state.price_type_reserved_logo_1 ){
                this.setState({price_type_reserved_logo_1: this.state.price_type_reserved});
            }
        } else {
            if( this.state.price_type_reserved != this.state.price_type_reserved_logo_0 ){
                this.setState({price_type_reserved_logo_0: this.state.price_type_reserved});
            }
        }

        
    }

    render(){

        if( !this.state.visible )
            return null;

        if( this.state.updating ){
            return <div className="SweetAlert">

                <div className="SweetAlertBg" ></div>

                <div className="SweetAlertBox" style={{marginTop: '-120px', width: '800px', marginLeft: '-400px'}}
                     onClick={se=>{se.preventDefault()}}>

                    <Loader />
                </div>
            </div>;
        }

        const fullPackedCode = [ this.state.num_eser_cont , this.state.cda_serie_num , this.state.cda_tipo_doc_num ,
            this.state.prg_doc , this.state.cda_polo_num ].join( '-' );

        const print_url = `${pweb().apiUrl()}/print/print/${fullPackedCode}/${this.state.logo_enable}/${this.state.price_type_reserved}/${this.state.discount_value}`;

        return <div className="SweetAlert">

            <form id="printform" style={{display: 'none'}} action={print_url}
                method="POST">
                <input type="hidden" name="access_token" value={pwebs().getState().authorizationKey} />
            </form>
            
            <div className="SweetAlertBg" ></div>

            <div className="SweetAlertBox" style={{position: 'absolute',marginTop: '-200px', width: '800px', marginLeft: '-400px'}}
                onClick={se=>{se.preventDefault()}}>

                <h3>Stampa</h3>

                <div className={"row text-left"}>
                    <div className={"col-sm-6 col-md-4"}>
                        <FormInputRadio label={"Primed"} checked={this.state.logo_enable=='1'?true:false}
                            onClick={se=>{this.setState({logo_enable: this.state.logo_enable==='1'?'0':'1'})}}/>
                    </div>

                </div>

                <div className={"row text-left"}>


                    <div className={"col-sm-6 col-md-8"}>
                        <FormInputSelect label={"Prezzi"} value={this.state.price_type_reserved_logo_1}
                                         direction={FormInputContainer.HORIZONTAL}
                                         onChange={se=>{this.setState({
                                             price_type_reserved_logo_1: se,
                                             price_type_reserved: se,
                                         })}} disabled={this.state.logo_enable!='1'} >

                            <FormInputSelectOption label={t('Prezzo di listino')} value="0" />
                            <FormInputSelectOption label={t('Prezzo a lei riservato')} value="1" />
                        </FormInputSelect>
                    </div>

                    <div className={"col-sm-6 col-md-4 text-right"} style={{marginTop: '23px'}}>
                        <a className={"btn btn-primary " + (this.state.logo_enable=='1'?'':'disabled')}  style={{width:'200px'}} onClick={se=>{se.preventDefault();this.setState({print:true})}}>Stampa</a>
                    </div>


                </div>
                <div className={"row text-left"}>

                    <div className={"col-sm-6 col-md-8"}>
                        <FormInputText
                            label={"Invia copia per email:"}
                            direction={FormInputContainer.HORIZONTAL}
                            value={this.state.copy_email}
                            disabled={this.state.logo_enable!='1'}
                            inputProps={{
                                placeholder: "Indirizzo email (Es. utente@email.com)",
                            }}
                            onChange={se=>{this.setState({copy_email: se.target.value})}}
                        />
                    </div>

                    <div className={"col-sm-6 col-md-4 text-right"} style={{marginTop: '23px'}}>
                        <a className={"btn btn-primary " + ((this.state.logo_enable=='1'&&this.state.send==0)?'':'disabled')} style={{width:'200px'}}
                           onClick={se=>{se.preventDefault();this.setState({send: 1})}}>
                            {this.state.send == 0 ? 'Invia email' : ''}
                            {this.state.send == 1 ? 'Invio in corso' : ''}
                            {this.state.send == 2 ? 'Invio completato' : ''}
                        </a>
                    </div>

                </div>


                <div className={"row text-left"} style={{marginTop: '20px'}}>
                    <div className={"col-sm-6 col-md-4"}>
                        <FormInputRadio label={"Personalizzato"} checked={this.state.logo_enable!='1'?true:false}
                                        onClick={se=>{this.setState({logo_enable: this.state.logo_enable==='1'?'0':'1'})}}/>
                    </div>


                </div>

                <Row className={"row text-left"}>

                    <div className={"col-sm-6 col-md-4"}>
                        <FormInputSelect label={"Prezzi"} value={this.state.price_type_reserved_logo_0}
                                         direction={FormInputContainer.HORIZONTAL}
                                         disabled={this.state.logo_enable=='1'}
                                         onChange={se=>{this.setState({
                                             price_type_reserved_logo_0: se,
                                             price_type_reserved: se})}} >

                            <FormInputSelectOption label={t('Prezzo di listino')} value="0" />
                            <FormInputSelectOption label={t('Prezzo a lei riservato')} value="1" />
                        </FormInputSelect>
                    </div>

                    {this.state.price_type_reserved_logo_0 == '0' && <div className="col-md-6">

                        <FormInputText
                            label={"Sconto su listino generale ( in percentuale )"}
                            direction={FormInputContainer.HORIZONTAL}
                            value={this.state.discount_value_list}
                            disabled={this.state.logo_enable=='1'}
                            inputProps={{
                                placeholder: "Sconto in % (Es: 10%)",
                                onBlur: se=>{this.setState({discount_value_list: parseInt(this.state.discount_value_list) + '%'})}
                            }}
                            onChange={se=>{this.setState({discount_value_list: se.target.value})}}
                        />

                    </div>}

                    {this.state.price_type_reserved_logo_0 == '1' && <div className="col-md-6">

                        <FormInputText
                            label={"Maggiorazione su prezzo riservato ( in percentuale )"}
                            direction={FormInputContainer.HORIZONTAL}
                            value={this.state.discount_value_res}
                            disabled={this.state.logo_enable=='1'}
                            inputProps={{
                                placeholder: "Maggiorazione in % (Es: 10%)",
                                onBlur: se=>{this.setState({discount_value_res: parseInt(this.state.discount_value_res) + '%'})}
                            }}
                            onChange={se=>{this.setState({discount_value_res: se.target.value})}}
                        />

                    </div> }

                </Row>

                {this.state.logo_enable != '1' && <React.Fragment>
                    <div className={"row text-left"}>

                        {/*<div className={"col-md-12 text-center"} style={{marginTop: '12px'}}>
                            <h3>Dati cliente</h3>
                        </div>*/}

                        <div className={"col-md-12"}>
                            <FormInputText label={t('Ragione sociale')} value={this.state.business_name}
                                           direction={FormInputContainer.HORIZONTAL}
                                           onChange={se=>{this.setState({business_name:se.target.value})}} />
                        </div>
                        <div className={"col-md-12"}>
                            <FormInputText label={t('Indirizzo')} value={this.state.address}
                                           direction={FormInputContainer.HORIZONTAL}
                                           onChange={se=>{this.setState({address:se.target.value})}} />
                        </div>
                        <div className={"col-md-4"}>
                            <FormInputText label={t('CAP')} value={this.state.cap}
                                           direction={FormInputContainer.HORIZONTAL}
                                           onChange={se=>{this.setState({cap:se.target.value})}} />
                        </div>
                        <div className={"col-md-5"}>
                            <FormInputText label={t('Città')} value={this.state.city}
                                           direction={FormInputContainer.HORIZONTAL}
                                           onChange={se=>{this.setState({city:se.target.value})}} />
                        </div>
                        <div className={"col-md-3"}>
                            <FormInputText label={t('Provincia')} value={this.state.province}
                                           direction={FormInputContainer.HORIZONTAL}
                                           onChange={se=>{this.setState({province:se.target.value})}} />
                        </div>
                        <div className={"col-md-12"}>
                            <FormInputText label={t('Telefono')} value={this.state.telephone}
                                           direction={FormInputContainer.HORIZONTAL}
                                           onChange={se=>{this.setState({telephone:se.target.value})}} />
                        </div>
                        <div className={"col-md-12"}>
                            <FormInputText label={t('EMail')} value={this.state.email}
                                           direction={FormInputContainer.HORIZONTAL}
                                           onChange={se=>{this.setState({email:se.target.value})}} />
                        </div>
                    </div>

                    <Row className={"text-left"}>
                        <div className={"col-md-6"}>
                            <FormInputText
                                label={"Invia copia per email:"}
                                direction={FormInputContainer.HORIZONTAL}
                                value={this.state.copy_email}
                                disabled={this.state.logo_enable=='1'}
                                inputProps={{
                                    placeholder: "Indirizzo email (Es. utente@email.com)",
                                }}
                                onChange={se=>{this.setState({copy_email: se.target.value})}}
                            />
                        </div>
                        <div className={"col-md-6"} style={{marginTop: '23px'}}>
                            <a className={"btn btn-primary " + ((this.state.logo_enable=='0'&&this.state.send==0)?'':'disabled')}
                               onClick={se=>{se.preventDefault();this.setState({send: 1})}}>
                                {this.state.send == 0 ? 'Invia email' : ''}
                                {this.state.send == 1 ? 'Invio in corso' : ''}
                                {this.state.send == 2 ? 'Invio completato' : ''}
                            </a>
                        </div>
                    </Row>


                </React.Fragment>}

                {this.state.discount_value_error && <Row style={{marginTop: '20px'}}>
                    <div className="col-md-8 col-md-offset-2">
                        <div className="alert alert-danger" role="alert">Valori inseriti non validi</div>
                    </div>
                </Row>}

                <div className={"row"}>
                    <div className={"col-sm-6 text-left"}>
                        {/*<a className="btn btn-default" onClick={this.close}>Chiudi</a>*/}
                    </div>
                    <div className={"col-sm-6 text-right"}>
                        <a className={"btn btn-primary " + (this.state.logo_enable!='1'?'':'disabled')}
                            onClick={se=>{se.preventDefault();this.setState({print:true})}}>Stampa</a>
                    </div>
                </div>

                <div className={"close"} style={{position:'absolute',right:'15px',top:'15px',opacity:'0.7'}}>
                    <i className={"fa fa-times"} onClick={this.close}></i>
                </div>

            </div> 

        </div>;
    }
}

class OrderCreationRoutes extends Component {
    render(){
        return <div>

            <Route url="/orderc">
                <OrderCreationBase />
            </Route>

        </div>;
    }
}

export { ProductChoiceForm , OrderCreationIndex , OrderCreationRoutes, OrderCreationUtils,
    PrintModal };